<template>
  <vueper-slides :arrows="false">
    <vueper-slide
        v-for="advertisement in advertisements"
        :key="advertisement.id+uniqueId()"
        :link="advertisement.link"
        :image="advertisement.photo"/>
  </vueper-slides>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: "Advertisement",
  components: {
    VueperSlides, VueperSlide,
  },
  props: ['advertisements']
}
</script>

<style scoped>

</style>