<template>
  <div class="home">
    <advertisement v-if="advertisements.length > 0" :advertisements="advertisements"></advertisement>
    <all-course-lists :categories="categories" :myCourses="myCourses"></all-course-lists>
  </div>
</template>

<script>
import AllCourseLists from '@/components/user/AllCourseLists.vue'
import axios from 'axios'
import Advertisement from "../components/user/Advertisement";

export default {
  components: {
    Advertisement,
    AllCourseLists
  },
  data() {
    return {
      courses: '',
      myCourses: '',
      advertisements: [],
      categories: [],
    }
  },
  methods: {
    loadCourses() {
      const url = 'courses?filter=category'
      axios.get(url).then((response) => {
        this.editMode = false
        this.categories = response.data
      }).catch((error) => {
        console.log(error)
      })
    },
    loadAdvertisements() {
      const url = 'advertisements'
      axios.get(url).then((response) => {
        this.advertisements = response.data
      }).catch((error) => {
        console.log(error)
      })
    },
    loadMyCourses() {
      const url = 'courses?filter=my-course'
      axios.get(url).then((response) => {
        this.myCourses = response.data
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.loadMyCourses()
    this.loadCourses()
    // this.loadAdvertisements()
  }
}
</script>