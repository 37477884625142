<template>
  <v-card class="cursor-pointer course" @click="followLink(course)">
    <v-img
        :aspect-ratio="16/9"
        width="100%"
        :src="course.photo"
    ></v-img>

    <v-card-title class="course-title">
      {{ title }}
    </v-card-title>

    <v-card-text class="pa-1">

      <div class="grey--text course-subtitle">
        {{ subTitle }}
      </div>

      <div class="d-flex align-baseline">
        <v-btn
            depressed
            color="success"
            class="mr-2 mb-2 mt-0 pa-0"
            text
        >
          <div v-if="course.discount && !course.subscribed">
            <span class="red--text"><del v-if="course.is_paid">৳{{ course.price }}</del></span> ৳{{ price }}
          </div>
          <div v-else>
            ৳{{ price }}
          </div>
        </v-btn>
        <div v-if="course.users_count" class="caption"><v-icon small v-text="'mdi-account-multiple'"></v-icon> {{course.users_count}}</div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['course'],
  data() {
    return {
      rating: 4.5,
    }
  },
  computed: {
    price() {
      if (this.course.subscribed) {
        return 'Enrolled'
      }
      let price = this.course.price
      let discount = this.course.discount || 0
      return this.course.is_paid ? price - discount : 'Free'
    },
    title() {
      if (this.course.title.length > 40) {
        return this.course.title.substring(0, 50) + '...';
      } else {
        return this.course.title;
      }
    },
    subTitle() {
      if (this.course.title.length > 40) {
        return this.course.sub_title.substring(0, 50) + '...';
      } else {
        return this.course.sub_title;
      }
    }
  },
  methods: {
    followLink(course) {
      if (course.subscribed)
        this.$router.push({name: 'Course', params: {courseId: course.id}});
      else
        this.$router.push({name: 'CourseDetails', params: {courseId: course.id}});
    },
  }
}
</script>
<style lang="scss" scoped>
.course {
  height: 340px;
}

.course-title {
  font-size: 1rem;
  padding-bottom: 0;
  padding-left: 4px;
  padding-top: 5px;
  line-height: normal;
}

.course-subtitle {
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 5px;
  line-height: normal
}

@media (max-width: 2000px) {
  .course {
    height: 340px;
  }
}

@media (max-width: 1200px) {
  .course {
    height: 320px;
  }
}

@media (max-width: 960px) {
  .course {
    height: 390px;
  }
}

@media (max-width: 900px) {
  .course {
    height: 360px;
  }
}

@media (max-width: 800px) {
  .course {
    height: 340px;
  }
}

@media (max-width: 700px) {
  .course {
    height: 310px;
  }
}

@media (max-width: 600px) {
  .course {
    height: 290px;
  }
}

@media (max-width: 500px) {
  .course {
    height: 260px;
  }
  .course-title {
    font-size: small;
  }
  .course-subtitle {
    font-size: smaller;
  }
}

@media (max-width: 400px) {
  .course {
    height: 220px;
  }
  .course-title {
    font-size: small;
  }
  .course-subtitle {
    font-size: smaller;
  }
}
</style>