<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-row>
      <!--        My Courses-->
      <v-col cols="12" v-if="authenticated && myCourses">
        <v-row>
          <v-col
              class="mt-2"
              cols="12"
          >
            <strong>My courses</strong>
          </v-col>
          <v-col
              v-for="(course, index) in myCourses"
              :key="course.id+index+course.id+uniqueId()"
              cols="6"
              md="4"
              sm="6"
              lg="3"
              class="pa-1 py-3"
          >
            <course-card :course="course"></course-card>
          </v-col>
        </v-row>
      </v-col>
      <!--        All Courses-->
      <v-col cols="12">
        <v-row
            v-for="category in categories"
            :key="'category'+category.id"
        >
          <v-col
              v-if="category.courses"
              class="mt-2"
              cols="12"
          >
            <strong>{{ category.name }}</strong>
          </v-col>
          <v-col
              v-for="course in category.courses"
              :key="course.id"
              cols="6"
              md="4"
              sm="6"
              lg="3"
              class="pa-1 py-3"
          >
            <course-card :course="course"></course-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!(categories)">
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="i in 6"
          :key="i"
      >
        <v-skeleton-loader
            type="image, article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CourseCard from './course/CourseCard.vue'
import {mapGetters} from 'vuex'

export default {
  props: ['categories', 'myCourses'],
  components: {
    CourseCard,
  },
  data: () => ({
    rating: 4.5,
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    followLink(course) {
      this.$router.push({name: 'Course', params: {courseId: course.id}});
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.course {
  height: 310px;
}

.course-title {
  font-size: 1rem;
  padding-bottom: 0;
  padding-left: 4px;
  padding-top: 5px;
  line-height: normal;
}

.course-subtitle {
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 5px;
  line-height: normal
}

@media (max-width: 1200px) {
  .course {
    height: 320px;
  }
}

@media (max-width: 960px) {
  .course {
    height: 390px;
  }
}

@media (max-width: 900px) {
  .course {
    height: 360px;
  }
}

@media (max-width: 800px) {
  .course {
    height: 340px;
  }
}

@media (max-width: 700px) {
  .course {
    height: 310px;
  }
}

@media (max-width: 600px) {
  .course {
    height: 290px;
  }
}

@media (max-width: 500px) {
  .course {
    height: 260px;
  }
  .course-title {
    font-size: small;
  }
  .course-subtitle {
    font-size: smaller;
  }
}

@media (max-width: 400px) {
  .course {
    height: 220px;
  }
  .course-title {
    font-size: small;
  }
  .course-subtitle {
    font-size: smaller;
  }
}
</style>